<template>
  <BaseDetail :is-loading="loading">
    <template #header>
      <nobr/>
    </template>
    <template #content>
      <DetailView
        v-if="dynamicValue"
        v-model="dynamicValue"
        ref="content"
        @save="$emit('save')"
        @delete="isDeleteDialog = true"
      />
      <BaseDialog
        role="delete"
        :text="$t('message.deletePassport')"
        v-model="isDeleteDialog"
        @confirm="$emit('delete')"
        :max-width="350"
        is-confirm-btn
        is-cancel-btn
      />
    </template>
  </BaseDetail>
</template>

<script>
import DetailView from '@/components/views/account/passport/detail/views/passportDetail/DetailView'
import BaseDetail from '@/components/base/BaseDetail'
import loading from '@/components/mixins/loading'
import BaseDialog from '@/components/base/BaseDialog'

export default {
  mixins: [loading],
  components: { BaseDialog, BaseDetail, DetailView },
  name: 'PassportDetail',
  props: {
    value: Object
  },
  data () {
    return {
      isDeleteDialog: false
    }
  },
  computed: {
    dynamicValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    handleDelete() {
      this.$emit('delete')
      this.isDeleteDialog = false
    }
  }
}
</script>

<style scoped>

</style>
